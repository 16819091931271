import React from 'react'

import AppLayout from '../components/AppLayout'
import Section from '../components/Section'
import { useNfts } from '../graphql/nft'
import { useHellos } from '../graphql/hellos'

const sectionConfig = {
  sections: [
    {
      id: 'nfts',
      label: 'NFTs',
      filter: {},
      sort: { created_at: 'DESC' },
      conn: { first: 7 },
      contentType: 'nfts',
      displayType: 'auctionCard'
    },
    {
      id: 'hellos',
      label: 'Hellos',
      filter: {},
      conn: { first: 15 },
      contentType: 'hellos',
      displayType: 'card'
    }
  ]
}

const filter = { review: 'pending' }

export default function Moderation() {
  const { nfts = [] } = useNfts(filter, { first: 10 })
  const { hellos = [] } = useHellos(filter, { first: 10 })

  const sectionsData = sectionConfig.sections.map((section: any) => {
    if (section.contentType === 'nfts') section.items = nfts
    if (section.contentType === 'hellos') section.items = hellos

    return section
  })

  const sections = React.useMemo(() => {
    return sectionsData.map((sectionData, i) => {
      return <Section config={sectionData} key={i} />
    })
  }, [sectionsData])

  return (
    <AppLayout publicPage={true}>
      <div className='max-w-screen-xl mx-auto w-full flex flex-col'>{sections}</div>
    </AppLayout>
  )
}
