import gql from 'graphql-tag'

export const UPDATE_HELLO = gql`
  mutation adminUpdateHello($id: String!, $adminUpdateHelloInput: AdminUpdateHelloInput!) {
    adminUpdateHello(id: $id, adminUpdateHelloInput: $adminUpdateHelloInput) {
      id
      url
      score
      review
      reason
      updated_at
      created_at
      deleted
      user {
        id
        name
      }
    }
  }
`

export const DELETE_HELLO = gql`
  mutation deleteHello($id: String!) {
    deleteHello(id: $id) {
      id
      url
      score
      review
      updated_at
      created_at
      deleted
      user {
        id
        name
      }
    }
  }
`

export const CREATE_HELLO = gql`
  mutation adminCreateHello($creatorId: String!, $file: Upload!) {
    adminCreateHello(creatorId: $creatorId, file: $file) {
      id
      url
      file_compressed
      file_thumbnail
    }
  }
`
