import clsx from 'clsx'
import { useEffect, useState } from 'react'

type CountdownProps = {
  className?: string
  digitClassName?: string
  time: Date | number | string
  showLabels?: boolean
}

const MS_PER_SECOND = 1000
const MS_PER_MINUTE = 60 * MS_PER_SECOND
const MS_PER_HOUR = 60 * MS_PER_MINUTE
const MS_PER_DAY = 24 * MS_PER_HOUR

export function CountdownDigit(props) {
  const { value, label, className, separator } = props
  return (
    <>
      <div className={clsx(className, 'flex flex-col')}>
        <span className='w-full'>{value}</span>
        <span className='text-s w-full'>
          {label}
          {label && value > 1 && 's'}
        </span>
      </div>
      {separator && <span className={clsx('mx-1', className.includes('hidden') && 'hidden')}>{separator}</span>}
    </>
  )
}

export function RawCountdown(props: CountdownProps) {
  const { time, className = '', showLabels = false, digitClassName } = props
  const [diff, setDiff] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now()
      const end = new Date(time || 0).getTime()

      setDiff(Math.max(end - now, 0))
    }, 100)

    return () => clearInterval(interval)
  }, [time])

  const days = Math.floor(diff / MS_PER_DAY)
    .toString()
    .padStart(2, '0')
  const hours = Math.floor((diff % MS_PER_DAY) / MS_PER_HOUR)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((diff % MS_PER_HOUR) / MS_PER_MINUTE)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor((diff % MS_PER_MINUTE) / MS_PER_SECOND)
    .toString()
    .padStart(2, '0')

  const widthRegex = /\s?w-[a-z0-9\/]+/g
  const customWidth = digitClassName?.match(widthRegex)?.length
  const spanClassName = `flex-1 ${customWidth || 'w-6'}`
  return (
    <div className={className + ' flex items-center  align-middle justify-between'}>
      <CountdownDigit
        className={clsx(spanClassName, diff < MS_PER_DAY && 'hidden', digitClassName)}
        value={days}
        label={showLabels && 'day'}
        separator={showLabels ? '' : ':'}
      />
      <CountdownDigit
        className={clsx(spanClassName, diff < MS_PER_HOUR && 'hidden', digitClassName)}
        value={hours}
        label={showLabels && 'hour'}
        separator={showLabels ? '' : ':'}
      />
      <CountdownDigit
        className={clsx(spanClassName, digitClassName)}
        value={minutes}
        label={showLabels && 'minute'}
        separator={showLabels ? '' : ':'}
      />
      <CountdownDigit className={clsx(spanClassName, digitClassName)} value={seconds} label={showLabels && 'second'} />
    </div>
  )
}

export default function Countdown(props: CountdownProps) {
  const { className = '', ...restProps } = props
  return (
    <div className={clsx(className, 'p-px bg-gradient-to-br from-indigo-800 to-primary rounded')}>
      <RawCountdown className='p-1 px-2 font-semibold bg-neutral-1 rounded text-center h-full' {...restProps} />
    </div>
  )
}
