import clsx from 'clsx'

import { formatPokenAmount } from '../../utils'

import Convert from './Convert'

type PokenAmountConvertionPosition = 'right' | 'bottom'

export interface PokenAmountProps {
  value?: number
  className?: string
  displayAddPokenButton?: boolean
  displayConvertion?: boolean
  convertionClassName?: string
  convertionPosition?: PokenAmountConvertionPosition
  chain?: string
  origin?: string
}

export default function PokenAmount(props: PokenAmountProps) {
  const {
    value,
    className = '',
    displayConvertion = false,
    convertionClassName = '',
    convertionPosition = 'right',
    chain
  } = props

  const chainLogo = (
    <img
      src={chain === 'BSC' ? '/images/icons/Binance.svg' : '/images/icons/ethereum.svg'}
      alt='chain logo'
      className='h-4 ml-1'
    />
  )

  return (
    <span
      className={clsx(
        `flex relative `,
        {
          // 'flex-col': convertionPosition === 'bottom',
          'items-center': convertionPosition === 'right'
        },
        className
      )}
    >
      <span className={`whitespace-nowrap	flex items-center relative`}>
        {value ? formatPokenAmount(value) : '-'} PKN
      </span>
      <span>{chain && chainLogo}</span>

      {displayConvertion && (
        <Convert
          value={value}
          className={clsx(
            `text-neutral-5 font-light`,
            {
              'absolute -bottom-3': convertionPosition === 'bottom',
              'ml-1': convertionPosition === 'right'
            },
            convertionClassName
          )}
        />
      )}
    </span>
  )
}
