import { gql } from '@apollo/client'

export const GET_HELLOS = gql`
  query adminHellos($conn: ConnectionInput!, $filter: FilterHelloInput, $sort: SortHelloInput) {
    adminHellos(conn: $conn, filter: $filter, sort: $sort) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      items {
        id
        url
        file_compressed
        file_thumbnail
        score
        review
        updated_at
        created_at
        moderated_at
        deleted
        user {
          id
          wallet
          cloud_wallet
          name
          profile_picture
          cover_picture
          certified
          professional
          aml_verified
          creator_type
        }
      }
    }
  }
`

export const GET_HELLO = gql`
  query hello($id: String!) {
    hello(id: $id) {
      id
      url
      file_compressed
      file_thumbnail
      score
      reason
      review
      updated_at
      created_at
      moderated_at
      deleted
      user {
        id
        wallet
        cloud_wallet
        name
        profile_picture
        cover_picture
        certified
        professional
        aml_verified
        creator_type
        hellos(conn: {}) {
          items {
            id
            url
            file_compressed
            file_thumbnail
            score
            review
            updated_at
            created_at
            moderated_at
            deleted
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`
