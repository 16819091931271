import { NftDisplayer } from '../utils/nft'

import CollectionSwiper from './CollectionSwiper'
import HelloCard from './HelloCard'
import NFTAuctionCard from './NFT/NFTAuctionCard'
import NFTAuctionHeroCard from './NFT/NFTAuctionHeroCard'
import { ViewMoreableComp } from './base/ViewMoreCard'

const loadingArray = new Array(8).fill(null)

function handleLoading(data, loading) {
  return loading ? loadingArray : data
}

type EditorialComponentMapping = {
  nfts: { [key: string]: ViewMoreableComp<NftDisplayer> }
  hellos: { [key: string]: ViewMoreableComp<{ hello?: any }> }
}

const COMPONENT_MAPPING: EditorialComponentMapping = {
  nfts: {
    auctionCard: NFTAuctionCard,
    auctionHeroCard: NFTAuctionHeroCard
  },
  hellos: {
    card: HelloCard
  }
}

type SectionProps = {
  config: any
  className?: string
  trackId?: string
}

const Section = (props: SectionProps) => {
  const { config, className, trackId } = props
  const { label, contentType, displayType, items } = config

  const Comp = COMPONENT_MAPPING[contentType][displayType]
  const ViewMoreComp = Comp?.ViewMore

  if (!Comp) return null

  const viewMoreLink = getViewMoreLink({ contentType })

  return (
    <CollectionSwiper title={label} viewMoreLink={viewMoreLink} className={className} trackId={trackId}>
      {handleLoading(items, false).map((item) => {
        const compProps = {
          [contentType.replace(/s$/, '')]: item
        }
        return <Comp {...compProps} key={item?.id} />
      })}
      {viewMoreLink && <ViewMoreComp href={viewMoreLink} />}
    </CollectionSwiper>
  )
}

function getViewMoreLink({ contentType }) {
  if (contentType === 'nfts') return { pathname: `/nfts` }
  if (contentType === 'hellos') return { pathname: `/hellos` }
  return null
}

export default Section
