import React from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { CubeIcon, CloudIcon } from '@heroicons/react/outline'
import { isBefore } from 'date-fns'

import { isActiveTimeLimited, NFT } from '../../utils/nft'
import { ClaimType, ClaimStatus } from '../../utils/types'
import PokenAmount from '../base/PokenAmount'
import Asset from '../base/Asset'
import Tooltip from '../base/Tooltip'
import ViewMoreCard, { ViewMoreableComp } from '../base/ViewMoreCard'
import Countdown from '../base/Countdown'

type NFTAuctionCardProps = {
  nft?: NFT
  className?: string
  trackId?: string
}

const CONTAINER_CLASSNAME = 'relative overflow-hidden w-72 border-0 rounded-md h-36 m-2 flex-shrink-0 cursor-pointer'

const NFTAuctionCard: ViewMoreableComp<React.FC<NFTAuctionCardProps>> = function NFTAuctionCard(
  props: NFTAuctionCardProps
) {
  const { className = '', nft, trackId } = props

  const isLoading = !nft
  const highestOffer = nft?.bids?.items?.[0]
  const displayedPrice = highestOffer?.amount || nft?.price || nft?.floor_price
  const isTimeLimited = isActiveTimeLimited(nft)
  const isClaimed = nft?.claim?.status !== ClaimStatus.pending

  const isClaimExpired = () => isBefore(new Date(nft?.claim?.validityDate), new Date())

  const getClaimableContent = () => {
    const type = nft?.claim?.type === ClaimType.physical ? 'Material item' : 'Digital item'
    const isExpired = isClaimExpired()

    let status = 'available'
    if (isClaimed) {
      status = 'claimed'
    } else if (isExpired) {
      status = 'expired'
    }

    return `${type} ${status}`
  }

  return (
    <Link href={`/nfts/${nft?.id}`}>
      <a>
        <article className={clsx(className, CONTAINER_CLASSNAME)} data-track={trackId}>
          <Asset
            src={nft?.file_thumbnail || nft?.image}
            alt=''
            resize={{ width: 200, height: 200 }}
            className='absolute w-full rounded-md '
            layout='fill'
            objectFit='cover'
            objectPosition=''
            mutable={false}
          />
          <div className='absolute flex w-full h-full -filter backdrop-blur rounded-md '>
            <div className='h-full w-36 p-2 flex-shrink-0'>
              {isLoading ? (
                <div className={clsx('h-full rounded w-full', !!nft || 'animate-pulse bg-neutral-4')} />
              ) : (
                <div
                  className={clsx(
                    'rounded relative h-full w-full shadow overflow-hidden',
                    !!nft || 'animate-pulse bg-neutral-4'
                  )}
                >
                  <Asset
                    playInViewPort
                    noPlayIcon
                    resize={{ width: 200, height: 200 }}
                    src={nft?.file_compressed || nft?.file_thumbnail || nft?.animation_url || nft?.image}
                    className={clsx('rounded object-cover h-full w-full absolute ')}
                    mutable={false}
                  />

                  <div className='flex ml-2 absolute bottom-0'>
                    {nft?.hasClaimable && !!nft?.claim ? (
                      <div
                        className={`w-6 h-6 mb-2 mr-2 ${
                          !isClaimed && !isClaimExpired()
                            ? 'bg-gradient-to-r from-primary to-indigo-800'
                            : 'bg-neutral-3 opacity-60'
                        } flex items-center rounded m-0`}
                      >
                        <Tooltip content={getClaimableContent()} placement='bottom' color='grey'>
                          {nft?.claim?.type === ClaimType.physical ? (
                            <CubeIcon className='mx-auto stroke-1' />
                          ) : (
                            <CloudIcon className='mx-auto stroke-1' />
                          )}
                        </Tooltip>
                      </div>
                    ) : null}

                    {nft?.unlockable_content ? (
                      <div className={clsx('w-6 h-6 mb-2 bg-neutral-3 flex items-center rounded opacity-60 z-3')}>
                        <Tooltip content='Secret content available' placement='bottom' color='grey'>
                          <img
                            src={nft?.pokAndPlay ? '/images/play.svg' : '/images/icons/lock-v2.svg'}
                            className={clsx('mx-auto', nft?.pokAndPlay && 'p-0.5')}
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className='flex flex-col py-2 pr-2 flex-grow overflow-hidden'>
              <div className={clsx('text-m truncate font-bold', nft || 'animate-pulse h-4 mb-1 bg-neutral-4 rounded')}>
                {nft?.name}
              </div>
              <div
                className={clsx(
                  'text-s truncate mb-1 ',
                  nft || 'animate-pulse h-4 bg-neutral-4 rounded text-neutral-4'
                )}
              >
                By {nft?.creator?.name}
              </div>

              {displayedPrice && (
                <PokenAmount
                  value={displayedPrice}
                  className={clsx('text-m h-4 truncate mb-1 ')}
                  displayConvertion
                  convertionClassName='text-neutral-8'
                />
              )}
              {isTimeLimited && (
                <Countdown
                  time={nft?.end_sale}
                  className='text-m text-center w-min h-7 z-2'
                  digitClassName='w-4'
                ></Countdown>
              )}
            </div>
          </div>
        </article>
      </a>
    </Link>
  )
}

NFTAuctionCard.ViewMore = function ProfileCardViewMore({ className = '', href }) {
  return <ViewMoreCard className={clsx(CONTAINER_CLASSNAME, className)} href={href} />
}

export default NFTAuctionCard
