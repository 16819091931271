import { OpenSeaAsset } from 'opensea-js/lib/types'

import client from '../apolloClient'
import { GET_NFT, GET_NFTS } from '../graphql/nft/queries'
import { Chain, ClaimType, ClaimStatus } from '../utils/types'

export type NftDisplayer = React.FC<{ nft: NFT }>

export type NFT = {
  id: string
  name: string
  auction_type: string
  description: string
  descriptionFr?: string
  descriptionEn?: string
  details: string
  detailsFr?: string
  detailsEn?: string
  image: string
  animation_url: string
  file_compressed: string
  file_thumbnail: string
  external_url: string
  endDate: Date
  owner: any
  serial: string
  bids?: { items: any[]; totalCount: number }
  purchases?: { items: any[]; totalCount: number }
  _asset?: OpenSeaAsset
  refreshBids?: Function
  token_id: string
  creator: any
  price: number
  contract: string
  status?: string
  reserved_price?: number
  created_at: string
  floor_price?: number
  unlockable_content?: boolean
  end_sale?: string
  tags?: string[]
  pokAndPlay?: boolean
  pokAndPlayPrice?: number
  pokAndPlayPeriod?: number
  pokAndPlayStats?: {
    events?: {
      start?: number
    }
    likes?: {
      like?: number
      dislike?: number
    }
    userLike?: boolean
    userDislike?: boolean
    score?: number
  }
  file_duration?: number
  hasClaimable?: boolean
  claim?: {
    validityDate: Date
    type: ClaimType
    description: string
    code: string
    message: string
    status?: ClaimStatus
    addresses: {
      mail?: string
      discord?: string
      telegram?: string
    }
  }
}

export type Offer = {
  id: string
  amount: number
  chain: Chain
  nft: NFT
  created_at: Date
}

export async function fetchNfts(filter = {}, conn = {}, sort = {}) {
  const { data } = await client.query({
    query: GET_NFTS,
    variables: {
      filter,
      conn,
      sort
    },
    fetchPolicy: 'no-cache'
  })

  return {
    nfts: data?.nfts?.items || []
  }
}

export async function fetchNft({ id }) {
  const { data } = await client.query({
    query: GET_NFT,
    variables: {
      filter: { id }
    },
    fetchPolicy: 'no-cache'
  })

  return {
    nfts: data?.nft
  }
}

export function isActiveTimeLimited(nft?: NFT) {
  return nft && nft?.auction_type === 'time_limited' && new Date(nft?.end_sale).getTime() - Date.now() > 0
}
