import { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { ConnectionInput } from '..'

import { GET_HELLOS, GET_HELLO } from './queries'
import { UPDATE_HELLO, DELETE_HELLO, CREATE_HELLO } from './mutations'

export function useHellos(filter = {}, conn: ConnectionInput = {}) {
  const [cursor, setCursor] = useState(conn.after || '')
  const [hellos, setHellos] = useState([])

  useEffect(() => {
    setHellos([])
    setCursor('')
  }, [filter])

  const { data, error, ...rest } = useQuery(GET_HELLOS, {
    variables: {
      filter,
      sort: { created_at: 'DESC' },
      conn: {
        ...conn,
        after: cursor
      }
    },
    fetchPolicy: 'no-cache'
  })

  if (error) console.error(error)

  useEffect(() => {
    const fetchedHellos = data?.adminHellos?.items || []
    const newHellos = [...hellos]
    fetchedHellos.forEach((fetched) => {
      if (!newHellos.find((hello) => hello.id === fetched.id)) newHellos.push(fetched)
    })
    setHellos(newHellos)
  }, [data?.adminHellos?.pageInfo?.endCursor])

  return {
    ...rest,
    hellos,
    hasMore: data?.adminHellos?.pageInfo?.hasNextPage,
    totalCount: data?.adminHellos?.totalCount,
    fetchMore: () => {
      setCursor(data?.adminHellos?.pageInfo?.endCursor)
    }
  }
}

export function useHello(id) {
  const { data, ...rest } = useQuery(GET_HELLO, {
    variables: {
      id,
      conn: {}
    },
    fetchPolicy: 'no-cache'
  })

  const hello = data?.hello || {}

  return { ...rest, hello }
}

export function useCreateHello() {
  return useMutation(CREATE_HELLO, {
    awaitRefetchQueries: true
  })
}

export function useUpdateHello() {
  return useMutation(UPDATE_HELLO, {
    awaitRefetchQueries: true,
    refetchQueries: ['hello']
  })
}

export function useDeleteHello() {
  return useMutation(DELETE_HELLO)
}
