import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'

import ViewMoreCard, { ViewMoreableComp } from './base/ViewMoreCard'
import Asset from './base/Asset'
import Avatar from './base/Avatar'

type HelloCardProps = {
  hello: any
}

const CONTAINER_CLASSNAME = 'rounded-lg bg-neutral-3 h-80 w-48 flex-shrink-0 m-2 relative flex flex-col items-center'

const HelloCard: ViewMoreableComp<React.FC<HelloCardProps>> = React.memo(function HelloCard(props: HelloCardProps) {
  const { hello } = props

  return (
    <Link href={`/hellos/${hello.id}`}>
      <a>
        <article className={clsx(CONTAINER_CLASSNAME, 'absolute overflow-hidden')} data-track='hello_card'>
          <Asset
            playInViewPort
            noPlayIcon
            preload='metadata'
            mutable={false}
            src={hello.file_compressed || hello.url}
            className='h-full object-cover w-full rounded-lg absolute'
          />
          <div className='absolute bottom-0 w-full flex p-2 bg-gradient-to-t from from-neutral-1 via-neutral-1 to-transparent items-center rounded-b-lg'>
            <Avatar className='w-6 h-6 mr-2 border-white border' image={hello.user?.profile_picture} forceGifResize />
            <span className='truncate text-m'>{hello.user?.name} </span>
          </div>
        </article>
      </a>
    </Link>
  )
})

HelloCard.ViewMore = function ProfileCardViewMore({ className = '', href }) {
  return <ViewMoreCard className={clsx(CONTAINER_CLASSNAME, className)} href={href} />
}

export default HelloCard
