import clsx from 'clsx'
import React from 'react'
import Link from 'next/link'

import { isActiveTimeLimited, NFT } from '../../utils/nft'
import PokenAmount from '../base/PokenAmount'
import Countdown from '../base/Countdown'
import Asset from '../base/Asset'
import ViewMoreCard, { ViewMoreCardProps } from '../base/ViewMoreCard'

type NFTAuctionCardHeroProps = {
  nft?: NFT
  className?: string
}

const CONTAINER_CLASSNAME = 'overflow-hidden w-72 border-0 rounded-md h-72 m-2 flex-shrink-0 cursor-pointer p-0.5'
interface NFTAuctionCardHeroSubComponents {
  ViewMore?: React.FC<ViewMoreCardProps>
}

const NFTAuctionHeroCard: React.FC<NFTAuctionCardHeroProps> & NFTAuctionCardHeroSubComponents = React.memo(
  function NFTAuctionHeroCard(props: NFTAuctionCardHeroProps) {
    const { className = '', nft } = props

    const isLoading = !nft
    const highestOffer = nft?.bids?.items?.[0]
    const displayedPrice = highestOffer?.amount || nft?.price || nft?.floor_price

    const isTimeLimited = isActiveTimeLimited(nft)

    return (
      <Link href={`/nfts/${nft?.id}`}>
        <a>
          <article
            className={clsx(
              className,
              CONTAINER_CLASSNAME,
              'bg-gradient-to-br  from-secondary via-turquoise to-indigo-700'
            )}
          >
            <div className={clsx('relative overflow-hidden rounded-md h-full w-full')}>
              <Asset
                playInViewPort
                noPlayIcon
                resize={{ width: 200, height: 200 }}
                src={nft?.file_compressed || nft?.file_thumbnail || nft?.animation_url || nft?.image}
                className={clsx(
                  'rounded object-cover h-full w-full shadow',
                  nft || 'animate-pulse bg-neutral-4 text-neutral-4'
                )}
                mutable={false}
              />

              <div className='absolute flex w-full backdrop-blur  h-28 bottom-0 rounded-b-md'>
                <div className='flex flex-col py-2 items-center px-2 flex-grow overflow-hidden '>
                  <div
                    className={clsx(
                      'text-hs  truncate font-bold w-full text-center',
                      isLoading && 'h-6  mb-1 animate-pulse bg-neutral-4 rounded'
                    )}
                  >
                    {nft?.name}
                  </div>
                  <div
                    className={clsx(
                      'text-s truncate mb-1 w-full text-center',
                      isLoading && 'h-6 animate-pulse bg-neutral-4 rounded'
                    )}
                  >
                    By {nft?.creator?.name}
                  </div>

                  {displayedPrice && (
                    <PokenAmount
                      value={displayedPrice}
                      className={clsx('text-m h-4 truncate mb-1 ')}
                      displayConvertion
                      convertionClassName='text-neutral-8'
                    />
                  )}
                  <div className='flex mt-auto'>
                    {isTimeLimited && (
                      <Countdown time={nft?.end_sale} className='text-m mr-1 flex-1' digitClassName='w-4' />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </Link>
    )
  }
)

NFTAuctionHeroCard.ViewMore = function ProfileCardViewMore({ className = '', href }) {
  return <ViewMoreCard className={clsx(CONTAINER_CLASSNAME, className)} href={href} />
}

export default NFTAuctionHeroCard
