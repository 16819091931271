/* eslint-disable import/no-unresolved */
import React, { ReactNode, useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Grid, FreeMode } from 'swiper'
import { useMediaQuery } from 'react-responsive'
import 'swiper/css/bundle'
import clsx from 'clsx'
import Link from 'next/link'

SwiperCore.use([Navigation, Grid, FreeMode])

export type CollectionSwiperProps = {
  title?: string
  children: ReactNode[]
  rainbow?: boolean
  viewMoreLink?: any
  className?: string
  trackId?: string
}

const SWIPER_SLIDE_STYLE = { width: 'min-content' }

const CollectionSwiper = React.forwardRef(function CollectionSwiper(props: CollectionSwiperProps, ref: any) {
  const { title, children = [], rainbow, viewMoreLink = '', className = '', trackId = '' } = props
  const isMediumScreen = useMediaQuery({ minWidth: 768 })

  const slides = useMemo(() => {
    return React.Children.map(children, (child, i) => (
      <SwiperSlide key={i} style={SWIPER_SLIDE_STYLE}>
        {child}
      </SwiperSlide>
    ))
  }, [children])

  const rainbowText = 'bg-gradient-to-r from-indigo-500 to-red text-transparent bg-clip-text'

  return (
    <section className={clsx('mb-4', className)} ref={ref} data-track={trackId}>
      <div className='flex justify-between items-center p-2'>
        <h2 className={clsx('text-hs font-semibold', rainbow && rainbowText)}>{title}</h2>
        {viewMoreLink && (
          <Link href={viewMoreLink}>
            <a className='text-m cursor-pointer' data-track={`${trackId}-viewall`}>
              View All
            </a>
          </Link>
        )}
      </div>
      <div>
        <Swiper modules={[FreeMode, Navigation]} slidesPerView={'auto'} navigation={isMediumScreen} freeMode mousewheel>
          {slides}
        </Swiper>
      </div>
    </section>
  )
})

export default CollectionSwiper
