import { Button, BUTTON_COLORS } from '@thepokencompany/ui'
import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'

export type ViewMoreCardProps = {
  className?: string
  href: any
}

interface ViewMoreSubComponent {
  ViewMore?: React.FC<ViewMoreCardProps>
}

export type ViewMoreableComp<T> = T & ViewMoreSubComponent

function ViewMoreCard({ className, href }) {
  return (
    <Link href={href}>
      <a
        className={clsx(
          className,
          'bg-gradient-to-br from-indigo-700 via-pink to-indigo-700 flex justify-center items-center'
        )}
      >
        <Button color={BUTTON_COLORS.white}>View All</Button>
      </a>
    </Link>
  )
}

export default React.memo(ViewMoreCard)
